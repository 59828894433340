import React, { useState, useEffect, useRef } from "react";
import { connect } from 'react-redux';
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faAngleDoubleRight, faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { dotPhraseMap } from "./dotPhrasesText";

import { setNoteText, setSoapNoteText } from '../../redux/tracker_note_input/actions'

import "./TrackerNoteInput.css";

const TrackerNoteInput = React.memo(function TrackerNoteInput(props) {
  const { noteText, soapNoteText, setNoteText, setSoapNoteText, patientId, view, setShowPatientData, showPatientData, currentPatientEncounters } = props;

  const [activeTab, setActiveTab] = useState("trackerNote");
  const [ lastNote, setLastNote ] = useState('');

  const textAreaRef = useRef();
  const textAreaRefSoap = useRef();


  useEffect(() => {
    if (currentPatientEncounters){
     let trackerNoteTimes = Object.keys(currentPatientEncounters);
     if (trackerNoteTimes.length > 0) {
       setLastNote(currentPatientEncounters[trackerNoteTimes[0]].text);
     }
    }
  }, [currentPatientEncounters])


  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  let paddingLeft = view === 'fullScreen' ? '52px' : '0px';

  return (
    <div className="tn-input-container" style={{ paddingLeft: paddingLeft }}>
      <div
        className="hide-show-arrow"
        onClick={() => setShowPatientData(!showPatientData)}
      >
        {showPatientData ? (
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        ) : (
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        )}
      </div>
      <Nav tabs className="note-tabs">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "trackerNote" })}
            onClick={() => {
              toggle("trackerNote");
            }}
          >
            Tracker Note
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "soapNote" })}
            onClick={() => {
              toggle("soapNote");
            }}
          >
            SBAR Note
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="trackerNote">
          <form>
            <div className="form-group">
              <textarea
                ref={textAreaRef}
                className="form-control tn-input"
                id="tn-input"
                placeholder="Enter your note"
                value={noteText}
                onKeyDown={(e)=> {
                  if (e.keyCode === 9) {
                    e.preventDefault();
                    const { selectionStart } = e.target;
                    let substr = noteText;
                    let colonPosition = substr.split('').indexOf(":", selectionStart);
                    if (textAreaRef.current) {
                      textAreaRef.current.selectionStart = textAreaRef.current.selectionEnd = colonPosition+1;
                    }
                  }
                }}
                onChange={e => {
                  let str = e.target.value;
                  if (str.match(/\.rtn/)) {
                    let newText = str.replace(/\.rtn/, dotPhraseMap.rtn);
                    setNoteText({ patientId, text: newText });
                  } else if (str.match(/\.onb/)) {
                    let newText = str.replace(/\.onb/, dotPhraseMap.onb);
                    setNoteText({ patientId, text: newText });
                  } else if (str.match(/\.alt/)) {
                    let newText = str.replace(/\.alt/, dotPhraseMap.alt);
                    setNoteText({ patientId, text: newText });
                  } else if (str.match(/\.trk/)) {
                    let newText = str.replace(/\.trk/, lastNote);
                    setNoteText({ patientId, text: newText });
                  } else if (str.match(/\.int/)) {
                    let newText = str.replace(/\.int/, dotPhraseMap.int);
                    setNoteText({ patientId, text: newText });
                  } else {
                    setNoteText({ patientId, text: e.target.value });
                  }
                }}
              />
            </div>
          </form>
        </TabPane>
        <TabPane tabId="soapNote">
          <form>
            <div className="form-group">
              <textarea
                ref={textAreaRefSoap}
                className="form-control tn-input"
                id="soap-note-input"
                placeholder="Enter your note - SOAP"
                value={soapNoteText}
                onChange={e =>
                  setSoapNoteText({ patientId, text: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.keyCode === 9) {
                    e.preventDefault();
                    const { selectionStart } = e.target;
                    let substrSoap = soapNoteText;
                    let colonPosition = substrSoap.split('').indexOf(":", selectionStart);
                    if (textAreaRefSoap.current) {
                      textAreaRefSoap.current.selectionStart = textAreaRefSoap.current.selectionEnd = colonPosition + 2;
                    }
                  }
                }}
              />
            </div>
          </form>
        </TabPane>
      </TabContent>
    </div>
  );
});

const mapActionToProps = { setNoteText, setSoapNoteText };

const mapStateToProps = state => {
  const { trackerNoteInput, patientMetadata: { patientPage: { currentPatient } }, encountersData: { encounters } } = state;
  let currentPatientEncounters = encounters[currentPatient];
  return {
    noteText: (trackerNoteInput[currentPatient] && trackerNoteInput[currentPatient]['text']) ? trackerNoteInput[currentPatient]['text'] : "",
    soapNoteText: (trackerNoteInput[currentPatient] && trackerNoteInput[currentPatient]['soapNoteText']) ? trackerNoteInput[currentPatient]['soapNoteText'] : "",
    patientId: currentPatient,
    currentPatientEncounters
  };
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(TrackerNoteInput);