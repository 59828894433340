import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem } from "reactstrap";
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faUsers } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as TaskIcon } from "../images/task_icon.svg";
import { ReactComponent as MynurseLogo } from "../images/mynurse_logo.svg";
import ContextMenuProfileSettings from "./ContextMenuProfileSettings"

import './SidebarDashboard.css'

const SidebarDashboard = React.memo(function SidebarDashboard(props) {
  const { fullName } = props;
  const [contextMenuProfileOpen, setContextMenuProfileOpen] = useState(false);
  const toggleContextMenu = () =>  setContextMenuProfileOpen(!contextMenuProfileOpen)

  const arrowIcon = (icon) => {
    return (
      <FontAwesomeIcon
        icon={icon}
        style={{
          marginRight: "10px",
          color: "white",
          fontSize: "22px"
        }}
      />
    );
  };

  return (
    <div className="navbar-container-dashboard">
      <div className="title-container">
           <MynurseLogo height="140px" />
      </div>
        <Nav className="nav-container-dashboard">
          <NavItem className="global-navigation">
            <NavLink to="/" className="menu-item">
              {arrowIcon(faExclamationTriangle)} {"Alerts"}
            </NavLink>
          </NavItem>
          <NavItem className="global-navigation">
            <NavLink to="/members" className="menu-item">
              {arrowIcon(faUsers)} {"Members"}
            </NavLink>
          </NavItem>
          <NavItem className="global-navigation">
            <NavLink to="/tasks" className="menu-item">
              <TaskIcon height="28px" style={{ margin: "0px 10px -4px 0px"}} />{" "} Tasks
            </NavLink>
          </NavItem>
        </Nav>
        <div className="user-name-wrapper">
        <div className="profile-icon" id="profile-icon">
          <ContextMenuProfileSettings
            toggleContextMenu={()=> toggleContextMenu()}
            isDropdownOpen={contextMenuProfileOpen}
          />
        </div>
        <p className="user-name-text">{fullName}</p>
        </div>
    </div>
  );
});

const mapStateToProps = state => {
  const { userData: { fullName } } = state;
  return { fullName };
};

export default connect(
  mapStateToProps,
  null
)(SidebarDashboard);


