export const dotPhraseMap = {
         rtn: `
Current health status:\n
Focus for today's encounter:\n
Response to previous interventions/Progress toward goal(s) including RPM:\n
Barriers (including SDOH):\n
Interventions:\n
Additional documentation:\n
Plan for next encounter:\n
Future PCP/specialty appts:\n
Initiative reminders (RPM, weight, COVID, etc):\n
Next scheduled WC f/u call (date/time):\n
Author of note + consultant(s):\n
`,
         onb: `
PCP and specialist(s):\n
HIPAA verification and preferences:\n
Patient Preferences updated in demographics: (Name, language, point of contact etc)\n
Preferred day/time and method of contact:\n
Onboarding complete (Y/N):\n
Provider notified of onboarding complete (Y/N)\n
Demographics complete (Y/N):\n
Device ordered (Y/N):\n
RPM schedule:\n
Long-term goals:\n
Home health:\n
Additional documentation:\n
Next scheduled f/u:\n
Author + Consultant(s):\n
`,
         alt: `
Time and date + type of alert + actual biometric reading:\n
Alert response (call, SMS text, etc.):\n
Current health status:\n
S/sx reviewed (per escalation workflow):\n
Contributing factors:\n
Additional biometric reading(s):\n
Intervention:\n
Additional documentation:\n
Plan for next encounter:\n
Next scheduled f/u:\n
SOAP note written/distributed/receipt confirmed:\n
Author of note + consultant(s):\n
`,
         int: `
Clinicians(s) involved (name and title NP, WC, pharmacy…):\n
Nature of encounter (routine 1:1, medication reconciliation, SOAP review…):\n
Changes to plan of care:\n
Suggested Interventions:\n
Date for next review:\n
Free text documentation regarding encounter, author:\n
`
       };