import axios from "axios";
import {baseURL} from "../../config";
var qs = require("qs");

// for the mock data:
// const getAlertsData = async params =>
//   axios({
//     method: "GET",
//     url: `/alerts/list?n_days=${params.time}&status=all&type=all&priority=alloffset=0`,
//     baseURL
//   });

const getAlertsData = async (params) => {
  let podToPass;
  if (params['pod']) {
    podToPass = params.pod[4]
  }
  return axios({
    method: "GET",
    url: `/alerts/list`,
    baseURL,
    params: {
      n_days: params.time || 1,
      status: params.status,
      type: params.type,
      priority: params.priority,
      offset: params.offset,
      pod: podToPass,
      search_term: params.search_term,
      show_all_panels: params.showAllPanels ? 1 : 0,
      selected_user_id: params.selectedUserId
    },
    withCredentials: true,

    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    }
  });
}

export default getAlertsData;
