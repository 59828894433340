import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faHospitalUser, faArrowRight, faAngleDoubleRight, faExclamationTriangle, faStar, faCircle } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons";

import { NavLink } from "react-router-dom";
import { PopoverBody, Popover } from "reactstrap";
import { setCurrentPatientPatientsPage} from "../../redux/patients_metadata/actions";
import { fetchEncounters } from '../../redux/encounter_notes/actions';
import { fetchDocuments } from "../../redux/documents/actions";
import { fetchSoapNotes } from '../../redux/soap_notes/actions';
import { setCustomStatsSelected } from '../../redux/patient_custom_stats/actions';
import ContactInfoCard from '../patient_screen/ContactInfoCard';
import StatusEditCard from "../patient_screen/StatusEditCard";
import Notification from '../shared_components/Notification';
import { getColor } from '../members/MembersRow'

import "./PatientBio.css";

export const weightHeightBox = (patientData) => {
  if (patientData && patientData !== {}) {
    return (
      <div className="icon-and-bio-box">
        <div className="icon-styled-user">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className="bio-box-wrapper">
          <div className="bio-box">
            <div className="bio-box-title">Weight</div>
            <div className="bio-box-value">
              {patientData.weight != '' ? patientData.weight : "-"}
            </div>
          </div>
          <div className="bio-box">
            <div className="bio-box-title">Height</div>
            <div className="bio-box-value">
              {patientData.height != '' ? patientData.height : "-"}
            </div>
          </div>
          <div className="bio-box">
            <div className="bio-box-title">BMI</div>
            <div className="bio-box-value">
              {patientData.bmi != '' ? patientData.bmi : "-"}
            </div>
          </div>
          <div className="bio-box">
            <div className="bio-box-title">Ethnicity</div>
            <div className="bio-box-value">
              {patientData.ethnicity != '' ? patientData.ethnicity : "-"}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const minutesBox = (patientData) => {
  let minutes, pam, risk, rpmDays;
  let minutesPct = '-';
  let rpmPct = 0;
  let backgroundColor = getColor(patientData.minutes, patientData.careType, patientData.status);
  let rpmBackground = "rgba(126, 232, 122, 0.7)";
  if (patientData && patientData !== {}) {
    minutes = 'minutes' in patientData ? patientData.minutes : "-"
    pam = "pam" in patientData ? patientData.pam : "-";
    risk = "risk" in patientData ? patientData.risk : "-";
    rpmDays = "rpmDays" in patientData ? Number(patientData.rpmDays) : 0;
    if (rpmDays && rpmDays < 16) {
      rpmPct = Math.round(rpmDays * 100 / 16);
    } else if (rpmDays >= 16) {
      rpmPct = 100;
    }
    rpmBackground = getRpmBackground(rpmPct);
    minutesPct = getMinutesPct(minutes, patientData.careType)
  }

  return (
    <div className="icon-and-bio-box">
      <div className="icon-styled-user">
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </div>
      <div className="bio-box-wrapper">
        <div className="bio-box">
          <div className="bio-box-title">Minutes</div>
          <div className="bio-box-value" style={{ backgroundColor: backgroundColor, width: '100%', textAlign: 'center' }}>
            {minutes} | {minutesPct}%
            </div>
        </div>
        <div className="bio-box">
          <div className="bio-box-title">PAM</div>
          <div className="bio-box-value">
            {pam}
          </div>
        </div>
        <div className="bio-box">
          <div className="bio-box-title">RPM</div>
          <div className="bio-box-value" style={{ backgroundColor: rpmBackground, width: '100%', textAlign: 'center' }}>
            {rpmDays > 0 ? `${rpmDays} | ${rpmPct} %` : '-'}
          </div>
        </div>
      </div>
    </div>
  )
}

export const stickyNotes = (patientObj) => {
  return (
    <div className="sticky-notes">
      <div className="sticky-note">
        {patientObj['stickyNotes']
          ? patientObj.stickyNotes
          : null}
      </div>
    </div>
  )
}

export const getStatusColor = (status) => {
  if (status === "active") {
    return "#2195f3";
  } else if (status === "churn") {
    return "rgba(255, 68, 68, 0.8)";
  } else if (status === "pause") {
    return "rgba(254, 162, 55, 0.8)";
  }
}

export const getRpmBackground = (n) => {
  if (n < 0) {
    return "rgb(236, 236, 236)";
  } else if( n < 40) {
      return "rgba(249, 70, 78, 0.7)";
    } else if (n > 40 && n < 60) {
      return "rgba(252, 188, 78, 0.7)";
    } else if (n > 60 && n < 80) {
      return "rgba(246, 225, 36, 0.7)";
    } else {
      return "rgba(126, 232, 122, 0.7)";
    }
}

export const getMinutesPct = (minutes, supportLvl) => {
  if (Number(minutes) == 0) {
    return 0
  } else if (!minutes || typeof Number(minutes) !== 'number' || !supportLvl) {
    return "-"
  }
  let minutesPct;
  if (supportLvl === "CCM+RPM" || supportLvl === "CCM/RPM") {
    minutesPct =Math.ceil((Number(minutes)/40)*100)
  } else if (supportLvl === "RPM only" || supportLvl === "CCM only") {
    minutesPct = Math.ceil((Number(minutes)/20)*100)
  }
  if (minutesPct > 100) {
    minutesPct = 100
  }
  return minutesPct
}



const PatientBio = React.memo(function PatientBio(props) {
  const bios = useSelector(state => state.patientBioData.bios);
  const currentPatient = useSelector(state => state.patientMetadata.alertsPage.currentPatient);
  const patient = bios[currentPatient];
  const notificationMessage = useSelector(state => state.notificationsData.notificationMessage);
  const dispatch = useDispatch();

  const { patientId } = patient;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [statusPopoverOpen, setStatusPopoverOpen] = useState(false);
  const [iconNameOpen, setIconNameOpen] = useState(false)


  const fullName = patient['firstName'] ? `${patient.firstName} ${patient.lastName}` : '-';
  const handleLinkClick = () => {
    dispatch(setCurrentPatientPatientsPage(patientId));
    dispatch(setCustomStatsSelected(false));
    dispatch(fetchEncounters(patientId));
    dispatch(fetchSoapNotes(patientId));
    dispatch(fetchDocuments(patientId));
  };

  const iconName = ({ isOpen, name, target }) => {
    return (
      <Popover
        isOpen={isOpen}
        placement="bottom"
        target={target}
        hideArrow={true}
        popperClassName="icon-name"
      >
        <PopoverBody className="tab-name-popover">{name}</PopoverBody>
      </Popover>
    );
  };

  const convertConditionsList = (arr) => {
    let result = '';
    for (let i = 0; i < arr.length; i++) {
      result += (i < arr.length - 1) ? arr[i] + ", " : arr[i]
    }
    return result;
  }


  if (!patient) {
    // TODO: make the notification show when no member is selected
    return (
      <>
        <div>Select a patient</div>
        <div className="notification-wrapper">
          {notificationMessage.isFor == "members" ||
          notificationMessage.isFor === "alerts" ? (
            <Notification />
          ) : null}
        </div>
      </>
    );
  } else {
    const initials = patient['firstName'] ? patient.firstName[0] + patient.lastName[0] : '-';
    return (
      <React.Fragment>
        <div className="notification-wrapper">
          {notificationMessage.isFor === "contact" ||
          notificationMessage.isFor === "statusEdit" ||
          notificationMessage.isFor === "members" ||
          notificationMessage.isFor === "alerts" ? (
            <Notification />
          ) : null}
        </div>
        <div className="patient-header">
          <div className="avatar-and-main-info">
            <div className="avatar-container" id="avatar-container-preview">
              <div
                className="d-none d-xl-flex avatar-preview"
                data-initials-preview={initials}
              ></div>
              <div id="check-icon-wrapper">
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    color: getStatusColor(patient.status),
                    fontSize: "11px"
                  }}
                />
              </div>
            </div>
            <Popover
              isOpen={statusPopoverOpen}
              placement="bottom"
              target="avatar-container-preview"
              hideArrow={true}
              toggle={() => setStatusPopoverOpen(!statusPopoverOpen)}
              trigger="legacy"
              popperClassName="wide-popover"
            >
              <PopoverBody className="filter-popover-body">
                <StatusEditCard
                  patientObj={patient}
                  setStatusPopoverOpen={val => setStatusPopoverOpen(val)}
                />
              </PopoverBody>
            </Popover>
            <div className="main-info-container">
              <div className="name-and-contact">
                <div className="patient-name">{fullName}</div>
                <div
                  className="clickable-icon-preview"
                  id="patient-contact-popover-preview"
                  onMouseEnter={() => setIconNameOpen(true)}
                  onMouseLeave={() => setIconNameOpen(false)}
                >
                  <FontAwesomeIcon
                    icon={faAddressCard}
                    style={{ fontSize: "22px", color: "#7A8EAB" }}
                  />
                </div>
                {iconName({
                  isOpen: iconNameOpen,
                  name: "Contact Info",
                  target: "patient-contact-popover-preview"
                })}
                <Popover
                  // className="pt-contact-popover"
                  isOpen={popoverOpen}
                  placement="bottom"
                  target="patient-contact-popover-preview"
                  hideArrow={true}
                  toggle={() => setPopoverOpen(!popoverOpen)}
                  trigger="legacy"
                  popperClassName="wide-popover"
                >
                  <PopoverBody className="filter-popover-body">
                    <ContactInfoCard
                      patientObj={patient}
                      setPopoverOpen={val => setPopoverOpen(val)}
                    />
                  </PopoverBody>
                </Popover>
              </div>
              <div className="main-info-row">
                <div className="main-info-row-item">
                  <span className="normal">Age:</span>{" "}
                  <span className="bold">
                    {patient["age"] ? patient.age : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">Gender:</span>{" "}
                  <span className="bold">
                    {patient["gender"] ? patient.gender : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">DOB:</span>{" "}
                  <span className="bold">
                    {patient["birthday"]
                      ? moment(patient.birthday).format("MM/DD/YYYY")
                      : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">MID:</span>{" "}
                  <span className="bold">
                    {patient["patientId"] ? patientId : "-"}
                  </span>
                </div>
              </div>
              <div className="main-info-row">
                <div className="main-info-row-item">
                  <span className="bold">
                    {patient["careType"] ? patient.careType : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">Enrollment Date:</span>{" "}
                  <span className="bold">
                    {patient["enrolmentDate"]
                      ? moment(patient.enrolmentDate).format("MM/DD/YYYY")
                      : "-"}
                  </span>
                </div>
                <div className="main-info-row-item">
                  <span className="normal">WC:</span>{" "}
                  <span className="bold">
                    {patient["primaryWc"] ? patient.primaryWc : "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="buttons-to-patient preview">
            <NavLink to="/patients">
              <div className="nav-to-patient-button" onClick={handleLinkClick}>
                Plan{" "}
                {
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      marginLeft: "10px",
                      color: "white",
                      fontSize: "14px"
                    }}
                  />
                }
              </div>
            </NavLink>
            <NavLink to="/document">
              <div
                className="nav-to-patient-double-arrow"
                onClick={handleLinkClick}
              >
                {
                  <FontAwesomeIcon
                    icon={faAngleDoubleRight}
                    style={{ color: "white", fontSize: "16px" }}
                  />
                }
              </div>
            </NavLink>
          </div>
        </div>

        <div className="top-row-patient-preview">
          <div className="minutes-box-wrapper">{minutesBox(patient)}</div>
          <div className="minutes-box-wrapper">{weightHeightBox(patient)}</div>

          <div className="icon-and-bio-box">
            <div className="icon-styled-user">
              <FontAwesomeIcon icon={faHospitalUser} />
            </div>
            <div className="bio-box-wrapper">
              <div className="bio-box">
                <div className="bio-box-title">Primary Provider</div>
                <div className="bio-box-value">
                  {patient["providers"] && patient.providers.length > 0
                    ? patient.providers[0].doctorName
                    : " "}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end top-row */}

        <div className="sticky-notes-primary-cond">
          <div className="icon-and-bio-box" style={{ flex: "3" }}>
            <div className="icon-styled-user">
              <FontAwesomeIcon icon={faStar} />
            </div>
            {stickyNotes(patient)}
          </div>
          <div className="icon-and-bio-box" style={{ flex: "2" }}>
            <div className="icon-styled-user">
              <FontAwesomeIcon icon={faClipboard} />
            </div>
            <div className="primary-cond-container preview">
              <div className="conditions-header">Primary Conditions</div>
              <div className="conditions-list">
                {patient.primaryConditions &&
                patient.primaryConditions.length > 0
                  ? convertConditionsList(patient.primaryConditions)
                  : null}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
});

export default PatientBio;

