import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { faTachometerAlt, faTh, faStar } from "@fortawesome/free-solid-svg-icons";
import { faClipboard, faHospital, faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { Popover, PopoverBody } from 'reactstrap';
import { weightHeightBox, minutesBox } from "../patient_preview/PatientBio";
import BiometricsLeft from "./BiometricsLeft";
import EncounterNotes from "../patient_screen/EncounterNotes";
import "./OverviewLeft.css";
import { doctorContactInfo } from '../patient_screen/OverviewContainer';
import Documents from '../patient_screen/Documents';
import StickyNotesCard from "../patient_screen/StickyNotesCard";

const OverviewLeft = React.memo(function OverviewLeft(props) {
  const { patientObj } = props;
  const [ activeTab, setActiveTab ] = useState("all");
  const [ tabNamesOpen, setTabNamesOpen ] = useState({medhistory: false, biometrics: false, tNotes: false, documents: false})

  const tabNamePopover = ({ isOpen, name, target }) => {
    return (
      <Popover
        isOpen={isOpen}
        placement="right"
        target={target}
        hideArrow={true}
      >
        <PopoverBody className="tab-name-popover">{name}</PopoverBody>
      </Popover>
    );
  };

  const convertConditionsList = (arr) => {
    if (!arr) {
      return null;
    }
    let result = '';
    for (let i = 0; i < arr.length; i++) {
      result += (i < arr.length - 1) ?  arr[i] + ", " :  arr[i]
    }
    return result;
  }

  return (
    <>
      <div className="overview-nav">
        <div
          className={classnames(
            { active: activeTab === "basicInfo" },
            "custom-tab"
          )}
          onClick={() => setActiveTab("basicInfo")}
          id="med-history-popover"
          onMouseEnter={() =>
            setTabNamesOpen({ ...tabNamesOpen, medhistory: true })
          }
          onMouseLeave={() =>
            setTabNamesOpen({ ...tabNamesOpen, medhistory: false })
          }
        >
          <div className="side-menu-pt-icon">
            <FontAwesomeIcon icon={faTh} />
          </div>
        </div>
        {tabNamePopover({
          isOpen: tabNamesOpen.medhistory,
          name: "Medical History",
          target: "med-history-popover"
        })}
        <div
          className={classnames(
            { active: activeTab === "biometrics" },
            "custom-tab"
          )}
          onClick={() => setActiveTab("biometrics")}
          id="biometrics-popover"
          onMouseEnter={() =>
            setTabNamesOpen({ ...tabNamesOpen, biometrics: true })
          }
          onMouseLeave={() =>
            setTabNamesOpen({ ...tabNamesOpen, biometrics: false })
          }
        >
          <div className="side-menu-pt-icon">
            <FontAwesomeIcon icon={faTachometerAlt} />
          </div>
        </div>
        {tabNamePopover({
          isOpen: tabNamesOpen.biometrics,
          name: "Biometrics",
          target: "biometrics-popover"
        })}
        <div
          className={classnames(
            { active: activeTab === "encounters" },
            "custom-tab"
          )}
          onClick={() => setActiveTab("encounters")}
          id="t-notes-popover"
          // style={{ marginBottom: "0em" }}
          onMouseEnter={() =>
            setTabNamesOpen({ ...tabNamesOpen, tNotes: true })
          }
          onMouseLeave={() =>
            setTabNamesOpen({ ...tabNamesOpen, tNotes: false })
          }
        >
          <div className="side-menu-pt-icon">
            <FontAwesomeIcon icon={faClipboard} />
          </div>
        </div>
        {tabNamePopover({
          isOpen: tabNamesOpen.tNotes,
          name: "Encounter Notes",
          target: "t-notes-popover"
        })}
        <div
          className={classnames(
            { active: activeTab === "documents" },
            "custom-tab"
          )}
          onClick={() => setActiveTab("documents")}
          id="documents-popover"
          style={{ marginBottom: "0em" }}
          onMouseEnter={() =>
            setTabNamesOpen({ ...tabNamesOpen, documents: true })
          }
          onMouseLeave={() =>
            setTabNamesOpen({ ...tabNamesOpen, documents: false })
          }
        >
          <div className="side-menu-pt-icon">
            <FontAwesomeIcon icon={faFolderOpen} />
          </div>
        </div>
        {tabNamePopover({
          isOpen: tabNamesOpen.documents,
          name: "Documents",
          target: "documents-popover"
        })}
      </div>
      {/* end inner panel */}
      <div className="overview-container-wrapper left">
        {activeTab === "all" || activeTab === "basicInfo" ? (
          <div id="basic-info">
            <div className="overview-top-row">
              {minutesBox(patientObj)}
              {weightHeightBox(patientObj)}
            </div>

            <div className="icon-and-component" style={{ marginTop: "15px" }}>
              <div className="icon-styled-pt">
                <FontAwesomeIcon icon={faStar} />
              </div>
              <StickyNotesCard
                patientId={patientObj.patientId}
                stickyNotes={
                  patientObj["stickyNotes"] ? patientObj.stickyNotes : ""
                }
                otherNotes={
                  patientObj["otherNotes"] ? patientObj.otherNotes : ""
                }
              />
            </div>

            <div className="conditions-and-contact-row margin-top-20">
              <div className="icon-and-component" style={{ flex: "3" }}>
                <div className="icon-styled-pt" style={{ marginRight: "15px" }}>
                  <FontAwesomeIcon icon={faClipboard} />
                </div>
                <div className="primary-cond-container no-margin-x">
                  <div className="conditions-header">Primary Conditions</div>
                  <div className="conditions-list">
                    {convertConditionsList(patientObj.primaryConditions)}
                  </div>
                </div>
              </div>
              <div
                className="icon-and-component inner-icon"
                style={{ flex: "5" }}
              >
                <div className="icon-styled-pt">
                  <FontAwesomeIcon icon={faHospital} />
                </div>
                <div className="doctor-contact-wrapper">
                  {doctorContactInfo(patientObj)}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {activeTab === "all" || activeTab === "biometrics" ? (
          <div>
            <div className="icon-and-component margin-top-20">
              <div className="icon-styled-pt">
                <FontAwesomeIcon icon={faTachometerAlt} />
              </div>
              <BiometricsLeft currentPatient={patientObj.patientId} />
            </div>
          </div>
        ) : null}
        {activeTab === "all" || activeTab === "encounters" ? (
          <div>
            <div className="icon-and-component margin-top-20">
              <div className="icon-styled-pt">
                <FontAwesomeIcon icon={faClipboard} />
              </div>
              <EncounterNotes
                currentPatient={patientObj.patientId}
                view="documentScreen"
              />
            </div>
          </div>
        ) : null}
        {activeTab === "all" || activeTab === "documents" ? (
          <div>
            <div className="icon-and-component margin-top-20">
              <div className="icon-styled-pt">
                <FontAwesomeIcon icon={faFolderOpen} />
              </div>
              <Documents currentPatient={patientObj.patientId} />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
});

export default OverviewLeft;
