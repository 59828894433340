import React, { useState } from "react";
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import { Card, Form, Input, Button, Error } from "./AuthForm";

function Signup() {
  const [isSignedup, setSignedup] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const postSignup = () => {
    if (password1 !== password2) {
      setIsError("Your password didn't match")
    } else {
      //add correct route
      axios
        .post("https://www.somePlace.com/auth/signup", {
          userName,
          password1
        })
        .then(result => {
          if (result.status === 200) {
            setSignedup(true);
          } else {
            setIsError(true);
          }
        })
        .catch(e => {
          setIsError(true);
        });
    }
  };

  if (isSignedup) {
    return <Redirect to="/" />;
  }

  return (
    <Card>
      <Form>
        <Input
          type="username"
          value={userName}
          onChange={e => {
            setUserName(e.target.value);
          }}
          placeholder="email"
        />
        <Input
          type="password"
          value={password1}
          onChange={e => {
            setPassword1(e.target.value);
          }}
          placeholder="password"
        />
        <Input
          type="password"
          value={password2}
          onChange={e => {
            setPassword2(e.target.value);
          }}
          placeholder="password"
        />
        <Button onClick={postSignup}>Sign Up</Button>
      </Form>
      <Link to="/login">Already have an account?</Link>
      {(isError && password1 !== password2) && <Error>{isError}</Error>}
      {(isError && password1 == password2)  && <Error>The username or password provided were incorrect!</Error>}
    </Card>
  );
}

export default Signup;
