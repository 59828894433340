import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { PopoverBody, Popover, Modal, ModalHeader, ModalBody, ModalFooter, Button, FormText, Input, Form, FormGroup, Label, Collapse } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Dropdown as DropdownSearch } from "semantic-ui-react";
import { fetchMembersData, setSortByMinutes, setCurrentFilter, setRpmFilter } from '../../redux/members/actions';
import './MembersFilter.css';

const MembersFilter = React.memo(function MembersFilter(props) {
  const dispatch = useDispatch();
  const clinics = useSelector(state => state.clinicsData.clinics);
  const totalMembersCount = useSelector(state => state.membersData.totalCount);
  const members = useSelector(state => state.membersData.members);
  const sortByMinutes = useSelector(state => state.membersData.sortByMinutes);
  const rpmFilter = useSelector(state => state.membersData.rpmFilter);
  const currentFilter = useSelector(state => state.membersData.currentFilter);
  const hasPanel = useSelector(state => state.userData.hasPanel);
  const users = useSelector(state => state.usersData.users);
  const [ searchTerm, setSearchTerm ] = useState('')

  const [isFilterPopoverOpen, setIsFilterPopoverOpen] = useState(false);

  const toggleFilterPopover = () => setIsFilterPopoverOpen(!isFilterPopoverOpen);

  const [isRpmModalOpen, setIsRpmModalOpen] = useState(false);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const [isClinicsModalOpen, setIsClinicsModalOpen] = useState(false);

  const toggleRpmModal = () => setIsRpmModalOpen(!isRpmModalOpen);

  const toggleStatusModal = () => setIsStatusModalOpen(!isStatusModalOpen);

  const toggleClinicsModal = () => setIsClinicsModalOpen(!isClinicsModalOpen);

  const filterIconColor = isFilterPopoverOpen ? "#2195F3" : "#7A8EAB";

  const [showAllPanels, setShowAllPanels] = useState(0);
  const [clinicId, setClinicId] = useState(null);

  const [ filtersCollapseOpen, setFiltersCollapseOpen] = useState(false);

  const toggleFiltersCollapse = () => setFiltersCollapseOpen(!filtersCollapseOpen);

  const resetRpmFilter = () => {
    dispatch(setRpmFilter({
      minDays: null,
      maxDays: null,
      minDaysPct: null,
      maxDaysPct: null,
      filterByWeightscale: false,
      allPanels: false
    }))
  }

  const statusCheckboxes = [
    {
      value: "my_panel",
      key: "myPanel",
      label: "My Panel (active)"
    },
    {
      value: "all_panels",
      key: "allPanels",
      label: "All Panels (active)"
    }
  ];

  const statusCheckboxesUncommon = [
    {
      value: "unclaimed",
      key: "unclaimed",
      label: "Unclaimed"
    },
    {
      value: "churned_my_panel",
      key: "churnedMyPanel",
      label: "Churned (my panel)"
    },
    {
      value: "churned_all",
      key: "churnedAll",
      label: "Churned (all panels)"
    },
    {
      value: "test",
      key: "test",
      label: "Test"
    }
  ];

  const statusCheckboxesToRender = () => {
    return statusCheckboxes.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="radio"
              name="status"
              value={item.value}
              checked={currentFilter == item.value}
              onChange={e => { dispatch(setCurrentFilter(e.target.value)) }} // removed resetRpmFilter() as it'll be reset on `Apply` button click
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    })
  }

  const statusCheckboxesToRenderUncommon = () => {
    return statusCheckboxesUncommon.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="radio"
              name="status"
              value={item.value}
              checked={currentFilter == item.value}
              onChange={e => {
                dispatch(setCurrentFilter(e.target.value));
              }}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  };

  const getApplyButtonMode = () => {
    const { minDays, minDaysPct, maxDays, maxDaysPct } = rpmFilter;
    if ((minDays && !maxDays) || (!minDays && maxDays)|| (minDaysPct && !maxDaysPct) || (!minDaysPct && maxDaysPct) || (!minDays && !maxDays && !minDaysPct && !maxDaysPct)) {
      return true
    } else {
      return false;
    }
  }

  const getDropdownSearch = () => {
    return (
      <DropdownSearch
        placeholder="Select a Clinic"
        search
        selection
        options={clinics}
        onChange={(e, params) => {
          setClinicId(params.value);

        }}
        style={{ lineHeight: "1.5" }}
      />
    )
  }

  const getRpmModal = () => {
    return (
      <Modal isOpen={isRpmModalOpen} toggle={() => {
        toggleRpmModal()
      }}>
        <ModalHeader>Filter by RPM Days</ModalHeader>
        <ModalBody className="rpm-filter-modal">
          <div className="rpm-filter-row top">
            <div className="rpm-filter-label">
              <span>Select RPM days range:</span>
            </div>
            <div className="rpm-filter-cells">
              <div className="rpm-filter-cell">
                <FormText color="muted">Min Days (n)</FormText>
                <Input
                  type="number"
                  className="rpm-days-input"
                  placeholder=""
                  value={rpmFilter.minDays || ""}
                  onChange={e => { dispatch(setRpmFilter({ ...rpmFilter, minDays: e.target.value })) }
                  }
                  disabled={rpmFilter.minDaysPct || rpmFilter.maxDaysPct ? true : false}
                />
              </div>
              <div className="rpm-filter-cell">
                <FormText color="muted">Max Days (n)</FormText>
                <Input
                  type="number"
                  className="rpm-days-input"
                  placeholder=""
                  value={rpmFilter.maxDays || ""}
                  onChange={e => { dispatch(setRpmFilter({ ...rpmFilter, maxDays: e.target.value })) }
                  }
                  disabled={rpmFilter.minDaysPct || rpmFilter.maxDaysPct ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="rpm-filter-row top">
            <div className="rpm-filter-label">
              <span>Or select RPM days range percentage:</span>
            </div>
            <div className="rpm-filter-cells">
              <div className="rpm-filter-cell">
                <FormText color="muted">Min Days (%)</FormText>
                <Input
                  type="number"
                  className="rpm-days-input"
                  placeholder=""
                  value={rpmFilter.minDaysPct || ""}
                  onChange={e => dispatch(setRpmFilter({ ...rpmFilter, minDaysPct: e.target.value }))}
                  disabled={rpmFilter.minDays || rpmFilter.maxDays ? true : false}
                />
              </div>
              <div className="rpm-filter-cell">
                <FormText color="muted">Max Days (%)</FormText>
                <Input
                  type="number"
                  className="rpm-days-input"
                  placeholder=""
                  value={rpmFilter.maxDaysPct || ""}
                  onChange={e => dispatch(setRpmFilter({ ...rpmFilter, maxDaysPct: e.target.value }))}
                  disabled={rpmFilter.minDays || rpmFilter.maxDays ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="rpm-filter-row top">
            <FormText>Weight Scale</FormText>
            <Input
              type="checkbox"
              className="rpm-days-input"
              value={rpmFilter.filterByWeightscale}
              onChange={e => dispatch(setRpmFilter({ ...rpmFilter, filterByWeightscale: !rpmFilter.filterByWeightscale }))}
            />
          </div>
          <div className="rpm-filter-row top">
            <FormText>All Panels</FormText>
            <Input
              type="checkbox"
              className="rpm-days-input"
              value={rpmFilter.allPanels}
              onChange={e => dispatch(setRpmFilter({ ...rpmFilter, allPanels: !rpmFilter.allPanels }))}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            dispatch(fetchMembersData({ rpmDaysFilter: rpmFilter, currentFilter: "rpm_days" }))
            setIsRpmModalOpen(false);
          }}
            disabled={isDisabled}>
            Apply Filter
            </Button>{" "}
          <Button color="secondary" onClick={() => setIsRpmModalOpen(false)}>
            Cancel
            </Button>
        </ModalFooter>
      </Modal>
    )
  }

  const getClinicsModal = () => {
    return (
      <Modal
        isOpen={isClinicsModalOpen}
        toggle={() => {
          toggleClinicsModal();
        }}
      >
        <ModalHeader>Filter by Clinic</ModalHeader>
        <ModalBody className="filter-by-clinic-modal">
          <div className="clinic-modal-body-top">
            {getDropdownSearch()}
          </div>
          <div className="clinic-modal-body-bottom">
            <FormText>All Panels</FormText>
            <Input
              type="checkbox"
              className="rpm-days-input"
              value={showAllPanels}
              onChange={e => setShowAllPanels(!showAllPanels)}
            />
            </div>
            {getSortOPtions()}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              dispatch(fetchMembersData({ clinicId, showAllPanels: showAllPanels ? 1 : 0, currentFilter: "clinic_id", sortByMinutes }));
              setIsClinicsModalOpen(false);
              setClinicId(null);
            }}
            disabled={clinicId ? false : true}
          >
            Apply Filter
          </Button>{" "}
          <Button color="secondary" onClick={() => setIsClinicsModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const dispatchSelectedSortOption = (option) => {
    if (option == "minutes") {
      dispatch(setSortByMinutes(true))
    } else {
      dispatch(setSortByMinutes(false));
    }
  }

  const getSortOption = (option, label) => {
    return (
      <FormGroup check inline>
        <Label check>
          <Input type="radio" name="members-sort-by" checked={sortByMinutes && option == "minutes" || !sortByMinutes && option == "rpm"} onChange={() => { dispatchSelectedSortOption(option) }} /> {label}
        </Label>
      </FormGroup>
    )
  }

  const getSortOPtions = () => {
    return (
      <div className="header-left-bottom">
        <div id="sort-by-legend">Sort By:</div>
        <Form>
          {getSortOption("minutes", "Minutes")}
          {getSortOption("rpm", "RPM")}
        </Form>
      </div>
    )
  }

  const getDropdownSearchWC = () => {
    return (
      <DropdownSearch
        placeholder="Select a WC"
        search
        selection
        options={users}
        onChange={(e, params) => {
          dispatch(setCurrentFilter(params.value));
        }}
        style={{ height: "min-content" }}
      />
    )
  }

  const getStatusModal = () => {
    return (
      <Modal
        isOpen={isStatusModalOpen}
        toggle={() => {
          toggleStatusModal();
        }}
      >
        <ModalHeader>Filter by Panels</ModalHeader>
        <ModalBody className="filter-by-panel-modal">
          <div className="filter-cols">
            <div className="m-filter-by-panel-col">
              {statusCheckboxesToRender()}
              {/* uncommon filters */}
              <Button size="sm" color="secondary" outline onClick={()=> {toggleFiltersCollapse()}} style={{ marginBottom: '1rem' }}>Less Common Filters</Button>
              <Collapse isOpen={filtersCollapseOpen}>
              {statusCheckboxesToRenderUncommon()}
              </Collapse>
              {/* end uncommon filters */}
            </div>
            <div className="m-filter-by-panel-col">
              <div id="filter-by-panel-divider">Or</div>
            </div>
            <div className="m-filter-by-panel-col">
              {getDropdownSearchWC()}
            </div>
          </div>
          {getSortOPtions()}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              dispatch(fetchMembersData({ currentFilter, sortByMinutes }));
              setIsStatusModalOpen(false);
            }}
          >
            Apply Filter
          </Button>{" "}
          <Button color="secondary" onClick={() => setIsStatusModalOpen(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const isDisabled = getApplyButtonMode();

  return (
    <React.Fragment>
      <div className="members-filter">
        <div className="members-header">
          <div className="membes-header-left">
            <div className="header-left-top">
              <div className="header-title"> Members </div>
              <div className="alerts-count-wrapper">{members.length}</div>
            </div>
          </div>
          {/* search bar */}
          <form
            onSubmit={e => {
              dispatch(
                fetchMembersData({ searchTerm, currentFilter: "search_term" })
              );
              e.preventDefault();
              setSearchTerm("");
            }}
          >
            <input
              className="form-control"
              type="text"
              placeholder="Search Member"
              aria-label="Search"
              style={{ marginLeft: "30px" }}
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            ></input>
          </form>
          {/* end search bar */}
        </div>
        <div id="MembersFilterPopover" className="filter-button-wrapper">
          <FontAwesomeIcon
            icon={faFilter}
            style={{ fontSize: "16px", color: filterIconColor }}
          />
        </div>
        <Popover
          trigger="legacy"
          placement="bottom"
          target="MembersFilterPopover"
          hideArrow={true}
          toggle={toggleFilterPopover}
          isOpen={isFilterPopoverOpen}
        >
          <PopoverBody className="filter-popover-body">
            <div className="card card-body">
              <div className="criteria-header-container">
                <div className="criteria-header status-header">Filter By:</div>
              </div>
              <div className="all-options-container">
                <div className="criteria-container">
                  <div>
                    <Button
                      outline
                      size="sm"
                      color="primary"
                      onClick={() => {
                        setIsStatusModalOpen(true);
                        setIsFilterPopoverOpen(false);
                        resetRpmFilter();
                      }}
                    >
                      Panels
                    </Button>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <Button
                      outline
                      size="sm"
                      color="primary"
                      onClick={() => {
                        setIsRpmModalOpen(true);
                        setIsFilterPopoverOpen(false);
                        resetRpmFilter();
                      }}
                    >
                      RPM days
                    </Button>
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <Button
                      outline
                      size="sm"
                      color="primary"
                      onClick={() => {
                        setIsClinicsModalOpen(true);
                        setIsFilterPopoverOpen(false);
                        resetRpmFilter();
                      }}
                    >
                      Clinic
                    </Button>
                  </div>
                </div>
              </div>
              {/* clear filter button */}
              <div
                className="criteria-header clear-filters-header"
                onClick={() => {
                  dispatch(fetchMembersData({ currentFilter: hasPanel ? "my_panel": "all_panels", sortByMinutes: true }));
                  setIsFilterPopoverOpen(false);
                  resetRpmFilter();
                }}
              >
                Clear Filters
              </div>
              {/* end clear filter button */}
            </div>
          </PopoverBody>
        </Popover>
        {getRpmModal()}
        {getClinicsModal()}
        {getStatusModal()}
      </div>
    </React.Fragment>
  );
});

export default MembersFilter;
