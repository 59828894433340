import React, { useState } from "react";
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios'
import { baseURL } from "../config";
import { proxy } from '../config'
import { Card, Form, Input, Button, Error } from "./AuthForm";
import { setIsLoggedIn } from '../redux/patients_metadata/actions'

const Login = React.memo(function Login(props) {
  const { setIsLoggedIn, isLoggedIn } = props;
  // const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  // axios.defaults.xsrfCookieName = "csrftoken";
  // axios.defaults.withCredentials = true;

  // document.cookie = "csrftoken=4blGOITrmq2GH46Q7GhbJAg2DWa3qfmWHeejzLumm573j5iisSjzTsGRO9lOZqpt";

  const postLogin = () => {
    axios
      .get(`/login`, {
        baseURL,
        params: {
          username: userName,
          password
        },
        // headers: {
        //   "X-CSRFToken": "4blGOITrmq2GH46Q7GhbJAg2DWa3qfmWHeejzLumm573j5iisSjzTsGRO9lOZqpt",
        //   // Authorization: `Bearer 4blGOITrmq2GH46Q7GhbJAg2DWa3qfmWHeejzLumm573j5iisSjzTsGRO9lOZqpt`
        //   'Cookie': "csrftoken=4blGOITrmq2GH46Q7GhbJAg2DWa3qfmWHeejzLumm573j5iisSjzTsGRO9lOZqpt"
        // },
        withCredentials: true
      })
      .then(result => {
        if (result.status === 200) {
          setIsLoggedIn(true);
          console.log(result);
        } else {
          setIsError(true);
        }
      })
      .catch(e => {
        setIsError(true);
      });
  }

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Card>
      <Form>
        <Input
          type="username"
          value={userName}
          onChange={e => {
            setUserName(e.target.value);
          }}
          placeholder="email"
        />
        <Input
          type="password"
          value={password}
          onChange={e => {
            setPassword(e.target.value);
          }}
          placeholder="password"
        />
        <Button onClick={postLogin}>Sign In</Button>
      </Form>
      <Link to="/signup">Don't have an account?</Link>
      {isError && <Error>The username or password provided were incorrect!</Error>}
    </Card>
  );
});


const mapStateToProps = state => {
  const { patientMetadata: { isLoggedIn } } = state;
  return {
    isLoggedIn
  };
};

const mapActionToProps = {
 setIsLoggedIn
};

export default connect(
  mapStateToProps,
  mapActionToProps
)(Login);