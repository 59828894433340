import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from 'classnames';
import moment from 'moment';
import prettyMilliseconds from 'pretty-ms';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Popover, PopoverBody, Button, FormGroup, Label, Input } from "reactstrap";

import { postEncounterNote, postSoapNote } from "../../redux/tracker_note_input/util";
import { setShowNotification, setNotificationMessage } from "../../redux/notifications/actions";
import Notification from '../shared_components/Notification';
import { updateEncounterNotes } from '../../redux/encounter_notes/actions';
import { getCommunicationType } from "../shared_components/TrackerNote"

import "./EncounterNotes.css";

const EncounterNotes = React.memo(function EncounterNotes(props) {
  const { currentPatient, view } = props;
  // replace connect with hooks
  const dispatch = useDispatch();
  const encounters = useSelector(state => state.encountersData.encounters);
  const soapNotes = useSelector(state => state.soapNotesData.soapNotes);
  const showNotification = useSelector(state => state.notificationsData.showNotification);
  const notificationMessage = useSelector(state => state.notificationsData.notificationMessage);

  const [filter, setFilter] = useState('encounter');
  const [ filteredNotesTime, setFilteredNotesTime ] = useState([]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filterIconColor = isFilterOpen ? "#2195F3" : "#7A8EAB";
  const twoWeeksInMs = 1209600000;

  const [currentEncounters, setCurrentEncounters] = useState({});
  const [currentSoapNotes, setCurrentSoapNotes] = useState({});
  const [ editMode, setEditMode ] = useState(false);
  const [ editableText, setEditableText ] = useState('');
  const [ addedMinutes, setAddedMinutes ] = useState(null);
  const [currentMinutes, setCurrentMinutes] = useState(null);

  useEffect(() => {
    if (encounters[currentPatient] && encounters[currentPatient] !== {} && soapNotes[currentPatient] && soapNotes[currentPatient] !== {}) {
      setCurrentEncounters(encounters[currentPatient]);
      setCurrentSoapNotes(soapNotes[currentPatient]);

      let encountersTime = Object.keys(encounters[currentPatient]);
      let soapNotesTime = Object.keys(soapNotes[currentPatient]);
      let allNotesTime = encountersTime.concat(soapNotesTime).sort((a, b) => b - a);

        if (filter === 'encounter') {
          setFilteredNotesTime(encountersTime);
        } else if (filter === 'soap') {
          setFilteredNotesTime(soapNotesTime);
        } else {
          setFilteredNotesTime(allNotesTime);
        }
    }

  }, [encounters, encounters[currentPatient], soapNotes, soapNotes[currentPatient], filter]);


  const [textToDisplay, setTextToDisplay] = useState('');
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedNoteType, setSelectedNoteType] = useState(null);
  const maxEncounterWidth = view === 'documentScreen' ? '452px': 'auto';


  const onSave = async (text) => {
    let response;
    try {
      if (selectedNoteType === "encounter") {
        response = await postEncounterNote({
          text,
          patientId: currentPatient,
          isComplete: 1,
          isTnUpdate: 1,
          tnToUpdate: currentEncounters[selectedTime].id,
          completeTask: 0,
          minutes: Number(addedMinutes)
        });
      } else {
        response = await postSoapNote({
          text,
          patientId: currentPatient,
          isComplete: 1,
          isUpdate: 1,
          soapId: currentSoapNotes[selectedTime].id,
          completeTask: 0
        });
      }
      if (response.status === 200) {
        dispatch(setShowNotification(true));
        setTimeout(() => { dispatch(setShowNotification(false)); dispatch(setNotificationMessage({})) }, 2000)
        dispatch(setNotificationMessage({ text: 'Your record has been saved', type: 'success', isFor: 'tnEdit' }));
      }
    } catch (err) {
      console.log(err)
      if (err.response.status == 405) {
        dispatch(setShowNotification(true));
        setTimeout(() => { dispatch(setShowNotification(false)); dispatch(setNotificationMessage({})) }, 2000)
        dispatch(setNotificationMessage({ text: 'You can edit only your notes', type: 'danger', isFor: 'tnEdit' }));
      } else {
        dispatch(setShowNotification(true));
        setTimeout(() => { dispatch(setShowNotification(false)); dispatch(setNotificationMessage({})) }, 2000)
        dispatch(setNotificationMessage({ text: 'Can\'t be edited at this time', type: 'danger', isFor: 'tnEdit' }));
      }
    }
  }

  const editableArea = () => {
    return (
      <div className="form-group">
        <FormGroup className="tn-edit-form">
          <Input
            type="textarea"
            name="text"
            id="tnEdit"
            rows="20"
            value={editableText}
            onChange={e => setEditableText(e.target.value)}
          />
        </FormGroup>
        <div className="edit-buttons">
          {selectedNoteType === "encounter" && (
              <FormGroup row className="add-minutes-form">
                <Label for="minutesInput" className="add-minutes-label">Add minutes</Label>
                <Input
                  type="number"
                  name="number"
                  id="minutesInput"
                  placeholder="0"
                  onChange={e => setAddedMinutes(e.target.value)}
                />
              </FormGroup>
          )}
        </div>
        <div className="edit-buttons">
          <Button
            color="primary"
            className="edit-save"
            onClick={() => {
              onSave(editableText);
              setEditMode(false);
              dispatch(updateEncounterNotes({
                patientId: currentPatient,
                time: selectedTime,
                text: editableText,
                callDuration: Number(currentMinutes) + Number(addedMinutes)
              }));
              setTextToDisplay(editableText);
            }}
          >
            Save
          </Button>
          <Button
            color="secondary"
            className="edit-save"
            onClick={() => {
              setEditMode(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  const textArea = () => {
    return <textarea className="tn-textarea" value={textToDisplay} readOnly />
  }

  const noteMetadata = (listNote) => {
    if (listNote['callDuration'] && listNote['license'] ) {
      return `${listNote.wcName},  ${listNote.license}: ${getCommunicationType(listNote.callLog)} ${listNote.callDuration} minutes`
    } else if (listNote['callDuration'] && !listNote['license']) {
      return `${listNote.wcName}: ${getCommunicationType(listNote.callLog)} ${listNote.callDuration} minutes`
    } else if (!listNote['callDuration'] && listNote['license'])  {
      return `${listNote.wcName},  ${listNote.license}: SOAP note`
    } else if (!listNote['callDuration'] && !listNote['license']) {
      return `${listNote.wcName}: SOAP note`
    } else {
      return `${listNote.wcName}`
    }
  }

  const notesOptionsValues = [
    {
      value: "encounter",
      key: "1",
      label: "Encounter Notes"
    },
    {
      value: "soap",
      key: "2",
      label: "Soap Notes"
    },
    {
      value: "all",
      key: "3",
      label: "All"
    }
  ];


  const notesOptions = () => {
    return notesOptionsValues.map(item => {
      return (
        <div className="checkbox" key={item.key}>
          <label>
            <input
              type="radio"
              name="notesRadio"
              value={item.value}
              checked={filter === item.value ? true : false}
              onChange={e => {setFilter(e.target.value); setTextToDisplay('')}}
            />
            <span className="checkbox-label">{item.label}</span>
          </label>
        </div>
      );
    });
  }

  const handleEditButtonClick = ({listNote, time, listNoteType}) => {
    setSelectedTime(time);
    setSelectedNoteType(listNoteType);
    setEditMode(true);
    setEditableText(listNote.text);
    setTextToDisplay(listNote.text);
    setCurrentMinutes(listNote.callDuration)
  }

  return (
    <div className="encounters-wrapper">
      <div className="encounters-list">
      {filteredNotesTime.map((time, index) => {
          time = Number(time);
          // check for this time in encouneter notes, is the time key is not there - check for it in the soap notes
          let listNote;
          let listNoteType;
          if (currentEncounters[time]) {
            listNote = currentEncounters[time];
            listNoteType = "encounter";
          } else {
            listNote = currentSoapNotes[time];
            listNoteType = "soap";
          }
          let msAgo = Date.now() - moment(time).add(7, "hours");
          return (
            <div className="metadata-and-icon" key={index}>
              <div
                className={classnames(
                  { active: time === selectedTime },
                  "encounter-note"
                )}
                onClick={() => {
                  setTextToDisplay(listNote.text);
                  setSelectedTime(time);
                  setSelectedNoteType(listNoteType);
                  setEditMode(false);
                }}
              >
                <div>
                  {/* Manually add 7 hours  */}
                  {prettyMilliseconds(msAgo, { compact: true })} ago
                </div>
                <div>{listNote ? noteMetadata(listNote) : null}</div>
                {/* treat time from Mongo as UTC, even thought it's local.
                Momemt expects UTC and converts it to local. */}
                <div>
                  {moment(time)
                    .utc()
                    .format("MM/DD/YYYY h:mm")}{" "}
                </div>
              </div>
              <div className="pencil-icon-wrapper">
                {msAgo < twoWeeksInMs ? (
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    onClick={() => handleEditButtonClick({ listNote, time, listNoteType })}
                  />
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
      <div className="divider-line"></div>
      <div className="encounter-selected" style={{ width: maxEncounterWidth }}>
        {showNotification && notificationMessage.isFor == "tnEdit" ? (
          <Notification />
        ) : null}
        {editMode ? editableArea() : textArea()}
      </div>
      <div>
      <div id="NotesPopover" className="filter-button-wrapper">
        <FontAwesomeIcon
          icon={faFilter}
          style={{ fontSize: "16px", color: filterIconColor }}
        />
      </div>
      <Popover
        trigger="legacy"
        placement="bottom-end"
        target="NotesPopover"
        hideArrow={true}
        isOpen={isFilterOpen}
        toggle={() => setIsFilterOpen(!isFilterOpen)}
        popperClassName="notes-filter-popover"
      >
        <PopoverBody className="filter-popover-body">
          <div className="card card-body">{notesOptions()}</div>
        </PopoverBody>
      </Popover>
      </div>
    </div>
  );
});

export default EncounterNotes;

