import React from 'react'
import { connect, useSelector } from "react-redux";
import { alertsListSpinner } from '../spinners'
import InfiniteScroll from "react-infinite-scroller";

import MembersRow from './MembersRow';
import MembersFilter from './MembersFilter';
import { fetchMembersData } from "../../redux/members/actions";

const MembersContainer = React.memo(function MembersContainer(props) {
  const MEMBERS_DISPLAYED = 30;
  const { members, totalMembersCount, isFetching } = props;

  // const totalPagesCount = Math.ceil(totalMembersCount / MEMBERS_DISPLAYED);

  if (!members) {
    return (<>An Error has occured</>)
  } else {
    return (
      <React.Fragment>
        <div className="alerts-and-footer">
          <div className="alerts-container">
            <MembersFilter />
            {isFetching ? (
              <div className="alerlts-list-spinner-wrapper">
                {alertsListSpinner()}
              </div>
            ) : (
              <div style={{ height: "90vh", overflow: "auto" }}>
                {members.map((member, i) => (
                  <MembersRow key={i} onClick={() => { }} member={member} />
                ))}
                {/* <InfiniteScroll
                  pageStart={0}
                  initialLoad={false}
                  loadMore={() => {
                    if (currentFilter) {
                      setPage(page => ({
                        ...page,
                        [currentFilter]: page[currentFilter] + 1
                      }));
                    }
                  }}
                  hasMore={page[currentFilter] < totalPagesCount}
                  loader={
                    isFetching ? (
                      <div className="loader" key={0}>
                        Loading ...
                      </div>
                    ) : null
                  }
                  useWindow={false}
                >
                  {members.map((member, i) => (
                    <MembersRow key={i} onClick={() => {}} member={member} />
                  ))}
                </InfiniteScroll> */}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
});

const mapActionsToProps = { fetchMembersData }

const mapStateToProps = state => {
  const { membersData: { members, totalCount, isFetching, error }, notificationsData: { showNotification, notificationMessage } } = state;
  return {
    members,
    showNotification,
    notificationMessage,
    totalMembersCount: totalCount,
    isFetching,
    error
  };
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(MembersContainer);

