import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { BrowserRouter as Router, Route } from "react-router-dom";

import App from './components/App';
import PatientContainer from '../src/components/patient_screen/PatientContainer'
import Login from '../src/auth_page/Login'
import Signup from '../src/auth_page/Signup'
import { fetchUser } from "./redux/user/actions";
import { fetchClinics } from "./redux/clinics/actions";
import { fetchUsers } from "./redux/users/actions";
import { fetchDoctors } from "./redux/doctors/actions";
import { fetchMembersData } from "./redux/members/actions";
import configureStore from './store/configureStore';
import DocumentScreen from  './components/document_screen/DocumentScreen';
import SignScreen from './components/sign_screen/SignScreen';
import Members from './components/members/Members';
import Tasks from './components/tasks/Tasks';

const store = configureStore();

store.dispatch(fetchUser())
store.dispatch(fetchUsers());
store.dispatch(fetchClinics());
store.dispatch(fetchDoctors());
store.dispatch(fetchMembersData({currentFilter: "my_panel", sortByMinutes: true}));
const routing = (
  <Provider store={store}>
    <Router>
      <>
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        {/* changed private route to route */}
        <Route exact path="/" component={App} />
        <Route path="/patients" component={PatientContainer} />
        <Route path="/members" component={Members} />
        <Route path="/tasks" component={Tasks} />
        <Route path="/document" component={DocumentScreen} />
        <Route path="/sign" component={SignScreen} />
      </>
    </Router>
  </Provider>
);

ReactDOM.render(routing, document.querySelector('#root')
)
